/* eslint-disable @typescript-eslint/no-unused-expressions */
import { makeStyles } from "@material-ui/core/styles";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BackHandIcon from "@mui/icons-material/BackHand";
import CampaignIcon from "@mui/icons-material/Campaign";
import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturb from "@mui/icons-material/DoDisturb";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, ListItemIcon } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
	deepPurple,
	green,
	grey,
	lightBlue,
	lime,
	red,
	teal,
} from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
export interface ShiftStatusOptions {
	checked: boolean;
	count: number;
	icon: string;
	label: string
  }

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 1;

const MenuProps: any = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 200,
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
	variant: "menu",
};

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		width: 300,
	},
	indeterminateColor: {
		color: "#f50057",
	},
	selectAllText: {
		fontWeight: 500,
	},
	selectedAll: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08)",
		},
	},
	menuList: {
		paddingTop: "0 !important",
		paddingBottom: "0 !important",
	},
}));

interface IShiftStatusFilterProps {
	statusOptions: ShiftStatusOptions[];
	setStatusOptions: any;
	// statusOptions: string[];
}
const ShiftStatusFilter: React.FC<
IShiftStatusFilterProps
> = ({ statusOptions, setStatusOptions }) => {
	const isAllStatusSelected =
	statusOptions.length > 0 &&  (statusOptions?.filter((data) => data.checked === true)?.length) === statusOptions.length;

	const handleChange = (event: any) => {
		const {
			target: { value },
		} = event;

			const options= statusOptions;
            const updatedStatus =   options.map((data)  => {
				if(value.includes(data.label)) {
                   data.checked = !data.checked;
				} 
				return data;
	})

			setStatusOptions(updatedStatus);
	};

	const classes = useStyles();


	const statusIcons: any = {
		Applied: (
			<BackHandIcon
				sx={{
					color: `${lightBlue[800]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Confirmed: (
			<ThumbUpIcon
				sx={{
					color: `${green[700]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Declined: (
			<CancelIcon
				sx={{
					color: `${red[800]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Viewed: (
			<RemoveRedEyeIcon
				sx={{
					color: `${lime[900]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Notified: (
			<CampaignIcon
				sx={{
					color: `${teal[900]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Assigned: (
			<AssignmentTurnedInIcon
				sx={{
					color: `${deepPurple[800]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		None: <Box></Box>,
		Cancelled: (
			<DoDisturb
				sx={{
					color: `${red[300]} !important`,
					fontSize: "16px",
				}}
			/>
		),
	};

	return (
		<Box sx={{ "& .MuiTextField-root": { width: "35ch" } }}>
			<FormControl sx={{ width: 242 }}>
				<InputLabel variant='filled' id='mutiple-select-label'>
					Shift Status
				</InputLabel>

				<Select
					labelId='demo-multiple-checkbox-label'
					id='demo-multiple-checkbox'
					multiple
					variant='filled'
					sx={{
						backgroundColor: "rgba(0, 0, 0, 0.04)",
						height: "48px",
						paddingTop: "5px",
						"& .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input":
							{
								paddingTop: "20px",
							},
					}}
					value={statusOptions.filter((item) => item.checked === true)}
					onChange={handleChange}
					renderValue={(selected) =>
					{
						const labels = selected.map(item => item.label);
						return labels.length > 2
							? labels[0] +" +"+ (labels.length - 1)
							: labels.join(", ") 
					}
					}
					MenuProps={{ ...MenuProps, classes: { list: classes.menuList } }}
				>
					{/* Add all Menu Item by default to select all locations */}
					
					{statusOptions.map((status: any) => (
						<MenuItem key={status.label} value={status.label}>
							<ListItemIcon>
								<Checkbox
									sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}
									checked={status.checked}
								/>
							</ListItemIcon>

							<ListItemText primary={status.label} />
							<Box sx={{ }}>
								{status.icon ? statusIcons[status.icon] : null}
							</Box>
						
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default ShiftStatusFilter;
