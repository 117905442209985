/* eslint-disable simple-import-sort/imports */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUserLocations } from "../../../../js/features/ManageWorkers/ManageWorkersSlice";
import {
	fetchskilllevels,
	getWorkerInsightReport,
} from "../../insight-dashboard/slices/worker-insight-report-slice";
import crossSkillingDashboardControllerData from "./use-cross-skilling-dashboard-data";
import { getSkillInsightReport } from "../../insight-dashboard/slices/skill-insight-report-slice";
import {
	Location,
	SkillInsightModel,
	SkillInsightsDetails,
	WorkerInsightModel, columnType,
} from "../../insight-dashboard/slices/insight-report-types";
import { getSkillPriorities } from "../../skills/store/skills-slice";

const useCrossSkillingDashboardController = () => {
	const {
		workersReportData,
		fetchWorkerInsightReportStatus,
		allLocations,
		allSkillLevels,
		skillsReportData,
		fetchSkillInsightReportStatus,
		allPriorities,
		isSkillPriorityEnabled,
	} = crossSkillingDashboardControllerData();

	const dispatch = useDispatch();

	const [selectedLocations, setSelectedLocations] = useState<Array<number>>([]);
	const [searchText, setSearchText] = useState("");
	const [filteredWorkerSummariesData, setFilteredWorkerSummariesData] =
		useState(null);
	const [filteredSkillSummariesData, setFilteredSkillSummariesData] =
		useState(null);
	const [selectedKPI, setKpi] = useState<
		| "skillsWithLessThenXWorkersKPI"
		| "interestedWorkersKPI"
		| "workersWithLessThenXSkillsKPI"
		| "workersWithAllSkillsKPI"
		| "overallWorkers"
	>("overallWorkers");
	const [overAllWorkersData, setOverallWorkersData] =
		useState(workersReportData);
	const [overAllSkillsData, setOverallSkillsData] = useState(skillsReportData);

	const [overAllColumns, setOverAllColumns] = useState<columnType[]>([]);

	const [kpiCount, setKpiCount] = useState({
		skillsWithLessThenXWorkersKPI: 0,
		interestedWorkersKPI: 0,
		workersWithLessThenXSkillsKPI: 0,
		workersWithAllSkillsKPI: 0,
	});

	useEffect(() => {
		dispatch(getWorkerInsightReport());
		dispatch(fetchUserLocations());
		dispatch(fetchskilllevels());
		dispatch(getSkillPriorities());
		dispatch(getSkillInsightReport());
	}, [dispatch]);

	useEffect(() => {
		const locations = allLocations.map((item: Location) => item.id);
		setSelectedLocations(locations);
	}, [allLocations]);

	useEffect(() => {
		setKpi("overallWorkers");

		if (selectedLocations.length == 0) {
			setKpiCount({
				skillsWithLessThenXWorkersKPI: 0,
				interestedWorkersKPI: 0,
				workersWithLessThenXSkillsKPI: 0,
				workersWithAllSkillsKPI: 0,
			});
		}
	}, [selectedLocations]);

	useEffect(() => {
		const workersColumns = [
			{ checked: true, field: "workerName", headerName: "Worker Name" },
			{ checked: true, field: "workerLocation", headerName: "Worker Location" },
			{ checked: true, field: "workcenterName", headerName: "Workcenter" },
			{ checked: false, field: "primaryJobName", headerName: "Primary Job" },
			{ checked: false, field: "joiningDate", headerName: "Joining Date" },
			{ checked: true, field: "totalSkills", headerName: "Total Skills" },

			{
				checked: true,
				field: "skillScore",
				headerName: "Total Skill Score",
			},
			{
				checked: true,
				field: "interestedSkillsCount",
				headerName: "Interested Skills",
			},
			{ checked: true, field: "workerBackupCount", headerName: "Worker Backup" },
		];

		const skillsColumns = [
			{ checked: true, field: "skillName", headerName: "Skill Name" },
			{
				checked: true,
				field: "skillPriority",
				headerName: "Skill Priority",
			},
			{
				checked: true,
				field: "interestedWorkersCount",
				headerName: "Interested Workers",
			},
			{
				checked: true,
				field: "totalWorkers",
				headerName: "Workers with Skill",
			},
		];
		if (
			selectedKPI === "skillsWithLessThenXWorkersKPI" ||
			selectedKPI === "interestedWorkersKPI"
		) {
			const skillColumns = isSkillPriorityEnabled
				? skillsColumns
				: skillsColumns.filter((item) => item.field !== "skillPriority");
			setOverAllColumns(skillColumns);
		} else {
			const workerInsightColumns = isSkillPriorityEnabled
				? workersColumns
				: workersColumns.filter((item) => item.field !== "skillScore");
			setOverAllColumns(workerInsightColumns);
		}
	}, [selectedKPI, isSkillPriorityEnabled]);

	const [workersWithLessThenXSkills, setworkersWithLessThenXSkills] = useState<
		WorkerInsightModel[]
	>([]);

	const [workersWithAllSkills, setWorkersWithAllSkills] =
		useState(workersReportData);

	const [skillsWithLessThanXWorkers, setskillsWithLessThanXWorkers] = useState<
		SkillInsightsDetails[]
	>([]);

	useEffect(() => {
		const workersWithLessThenXSkillsData: WorkerInsightModel[] = [];

		const countObj = kpiCount;

		//Filter by locations
		if (selectedLocations.length === 0) {
			setworkersWithLessThenXSkills(workersWithLessThenXSkillsData);
			setOverallWorkersData(workersReportData);

			return;
		}
		let filteredData: WorkerInsightModel[] = [];

		if (selectedLocations?.length === allLocations.length) {
			filteredData = [...workersReportData];
		} else {
			workersReportData
				.filter((worker) => selectedLocations.includes(worker.locationId))
				.map((item) => {
					const workerData = { ...item };
					filteredData.push(workerData);
				});
		}

		setOverallWorkersData(filteredData);

		filteredData.forEach((item) => {
			let totalSkillsCount = 0;
			item.workerSkillCountByLevel.forEach((skill) => {
				const lev = allSkillLevels.find((level) => level.level === skill.level);
				if (lev && lev.isQualifiedAsSkilled) {
					totalSkillsCount += skill.count;
				}
			});

			if (totalSkillsCount < 3) {
				workersWithLessThenXSkillsData.push(item);
			}
		});

		if (workersWithLessThenXSkillsData.length === 0) {
			const sortedWorkerData: WorkerInsightModel[] = [...filteredData].sort(
				(a, b) => {
					const sumA = a.workerSkillCountByLevel.reduce(
						(acc: any, obj: any) => acc + obj.count,
						0
					);
					const sumB = b.workerSkillCountByLevel.reduce(
						(acc: any, obj: any) => acc + obj.count,
						0
					);
					return sumB - sumA;
				}
			);
			countObj.workersWithLessThenXSkillsKPI = 0;
			setworkersWithLessThenXSkills(sortedWorkerData);
		} else {
			countObj.workersWithLessThenXSkillsKPI =
				workersWithLessThenXSkillsData.length;
			setworkersWithLessThenXSkills(workersWithLessThenXSkillsData);
		}
		setKpiCount(countObj);
	}, [
		workersReportData,
		selectedLocations,
		allLocations,
		kpiCount,
		allSkillLevels,
	]);

	useEffect(() => {
		try {
			const skillsWithLessThanXWorkersData: SkillInsightsDetails[] = [];
			const countObj = kpiCount;

			//Filter by location
			const selectedLocationWithIds: Location[] = [];

			selectedLocations?.map((location: number) => {
				const data = allLocations.find((x: Location) => x.id === location);
				selectedLocationWithIds.push({
					id: data?.id,
					name: data?.name,
					isActive: data?.isActive,
				});
			});

			if (selectedLocationWithIds.length === 0) {
				setskillsWithLessThanXWorkers(skillsWithLessThanXWorkersData);
				setOverallSkillsData(skillsReportData);
				return;
			}

			let filtereSkillsdDataByLoc: SkillInsightsDetails[] = [];

			if (selectedLocationWithIds?.length !== allLocations.length) {
				skillsReportData.forEach((item: SkillInsightsDetails) => {
					if (
						selectedLocationWithIds.some((location) =>
							item.locationIds.includes(location.id)
						)
					) {
						const skillData = { ...item };
						filtereSkillsdDataByLoc.push(skillData);
					}
				});
			} else {
				filtereSkillsdDataByLoc = [...skillsReportData];
			}

			setOverallSkillsData(filtereSkillsdDataByLoc);
			let totalInterestedWorkersFinalCount = 0;
			filtereSkillsdDataByLoc.forEach((item) => {
				let totalWorkersCount = 0;
				item.workersSkillLevel.forEach((skill) => {
					if (selectedLocations.length) {
						// If filter is applied
						if (
							selectedLocations.includes(skill.locationId)
						) {
							skill.workerCount.forEach((level) => {
								const selectedSkillLevel = allSkillLevels.find(skillLevel => skillLevel.level === level.level);
								if (selectedSkillLevel && selectedSkillLevel.isQualifiedAsSkilled) {
									totalWorkersCount += level.count;
								}
							});
						}
					} else {
						skill.workerCount.forEach((level) => {
							const selectedSkillLevel = allSkillLevels.find(skillLevel => skillLevel.level === level.level);
							if (selectedSkillLevel && selectedSkillLevel.isQualifiedAsSkilled) {
								totalWorkersCount += level.count;
							}
						});
					}
				})
				if (totalWorkersCount < 3) {
					skillsWithLessThanXWorkersData.push(item);
				}

				const interestedWorkersObject = item.interestedWorkersCount
					.filter((x) => selectedLocations.includes(x.locationId)) // Filter by selected locations
					.flatMap((x) => x.count);
				const totalInterestedWorkersCount = interestedWorkersObject.reduce(
					(sum, skill) => sum + skill,
					0
				);
				totalInterestedWorkersFinalCount += totalInterestedWorkersCount;
			});


			countObj.interestedWorkersKPI = totalInterestedWorkersFinalCount;

			if (skillsWithLessThanXWorkersData.length === 0) {
				const sortedSkillsData: SkillInsightsDetails[] = [
					...filtereSkillsdDataByLoc,
				].sort((a, b) => {
					const sumA = a.workersSkillLevel.reduce(
						(acc: any, obj: any) => acc + obj.count,
						0
					);
					const sumB = b.workersSkillLevel.reduce(
						(acc: any, obj: any) => acc + obj.count,
						0
					);
					return sumA - sumB;
				});
				countObj.skillsWithLessThenXWorkersKPI = 0;
				setskillsWithLessThanXWorkers(sortedSkillsData);
			} else {
				countObj.skillsWithLessThenXWorkersKPI =
					skillsWithLessThanXWorkersData.length;
				setskillsWithLessThanXWorkers(skillsWithLessThanXWorkersData);
			}

			setKpiCount(countObj);
		} catch (error) {
			console.log("Error in insight dashboard",error);
		}
	}, [skillsReportData, selectedLocations, allLocations, kpiCount, allSkillLevels]);

	const priorities = allPriorities.reduce((acc: any, obj: any) => {
		acc[obj.id] = obj;
		return acc;
	}, {});

	return [
		{
			allLocations,
			selectedLocations,
			allSkillLevels,
			workersReportData,
			fetchWorkerInsightReportStatus,
			searchText,
			overAllColumns,
			filteredWorkerSummariesData,
			skillsReportData,
			filteredSkillSummariesData,
			fetchSkillInsightReportStatus,
			isSkillPriorityEnabled,
			skillsWithLessThanXWorkers,
			workersWithLessThenXSkills,
			workersWithAllSkills,
			selectedKPI,
			kpiCount,
			overAllSkillsData,
			overAllWorkersData,
			allPriorities,
			priorities,
		},
		{
			setSelectedLocations,
			setSearchText,
			setOverAllColumns,
			dispatch,
			setFilteredWorkerSummariesData,
			setFilteredSkillSummariesData,
			setKpi,
		},
	];
};

export default useCrossSkillingDashboardController;
