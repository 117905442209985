import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro-6";
import React, { FC } from "react";

import SkeletonLoadingOverlay from "../../../../utils/use-skeleton-loading-overlay";
import useTableHeight from "../../../../utils/use-table-height";
import useWorkerRequestsData from "../../controllers/use-worker-requests-data";
import { requests } from "../../data";
import { RegularShiftRequest } from "../../store/worker-requests-types";
import useFullTimeRequestsColumns from "../controllers/use-full-time-request-columns";
import EmptyOverlay from "./empty-overlay";

interface FullTimeRequestsTableProps {
	openCreateNewShiftDialog: ((date: string) => void) | undefined;
	openShiftDetailsDialog: ((e: string) => void) | undefined;
	columns: any[];
	rows?: RegularShiftRequest[];
}

const FullTimeRequestsTable: FC<FullTimeRequestsTableProps> = ({
	openCreateNewShiftDialog,
	openShiftDetailsDialog,
	columns,
	rows,
}) => {
	const { boxRef, height } = useTableHeight();

	const {
		allRegularRequests,
		getAllRegularRequestsStatus,
		requestActionStatus,
	} = useWorkerRequestsData();

	const t = rows?.map((request: RegularShiftRequest) => ({
		...request,
		openCreateNewShiftDialog: openCreateNewShiftDialog,
		openShiftDetailsDialog: openShiftDetailsDialog,
	}));

	return (
		<Box
			ref={boxRef}
			sx={{
				width: "88vw",
				overflow: "hidden",
				height: "100%",
			}}
		>
			<DataGridPro
				rows={t ?? []}
				columns={columns}
				getRowHeight={() => "auto"}
				disableRowSelectionOnClick
				disableColumnSelector
				disableDensitySelector
				disableColumnMenu
				pagination
				pageSizeOptions={[25, 50, 100, 500]}
				loading={
					getAllRegularRequestsStatus === "pending" ||
					requestActionStatus === "pending"
				}
				sx={{
					position: "sticky",
					height: height,
					"&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
						py: "10px",
					},
					border: "none !important",
					"& .MuiTablePagination-root": {
						marginRight: "60px",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						height: "0px",
					},
					".MuiDataGrid-cell": {
						"&:focus": {
							outline: "none",
						},
						"&.hover-column-cell": {
							position: "sticky",
							right: 0,
							zIndex: 1,
						},
						"& .hover-content": {
							transition: "opacity 0.3s ease-in-out",
							opacity: 0,
						},
					},
					".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
						opacity: 1,
						backgroundColor: "#f5f5f5",
					},
				}}
				slots={{
					loadingOverlay: () => <SkeletonLoadingOverlay />,
					noRowsOverlay: () => <EmptyOverlay />,
				}}
			/>
		</Box>
	);
};

export default FullTimeRequestsTable;
