/* eslint-disable no-mixed-spaces-and-tabs */
import { tr } from "date-fns/locale";
import { useConfirm } from "material-ui-confirm";
import { useCallback, useEffect, useState } from "react";

import { mergeObjects } from "../../../../../../utils/helpers";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import { AllocationSettings } from "../../../../store/gat-admin-slice-types";

type Rule = {
	shiftAllocationTypeId: number;
	isDefault: boolean;
	jobIds: string;
};

interface ShiftAllocationRulesProps {
	locationData: any;
	setLocationData: any;
	creatingNewLocation: boolean;
}

const useShiftAllocationConfigController = ({
	locationData,
	setLocationData,
	creatingNewLocation,
}: ShiftAllocationRulesProps) => {
	const confirm = useConfirm();
	const { shiftAllocationTypes, allJobsByLocation, allJobsByLocationObject } =
		useGatAdminConfigData();

	const [jobDialogOpen, setJobDialogOpen] = useState(-1);
	const [seniorityDialogOpen, setSeniorityDialogOpen] =
		useState<boolean>(false);
	const [workcenterDialogOpen, setWorkcenterDialogOpen] =
		useState<boolean>(false);
	const [locationDialogOpen, setLocationDialogOpen] = useState<boolean>(false);

	const { allocationTypes, status, errorMessage } = shiftAllocationTypes;

	const [shiftAllocationRules, setShiftAllocationRules] = useState<
		AllocationSettings[]
	>([]);

	useEffect(() => {
		const baseSettings = {
			jobIds: null,
			locationId: locationData.id,
			defaultWindowSizeMins: 0,
			urgentWindowSizeMins: 0,
			tieBreakerProp: null,
			id: 0,
		};

		const newRules = creatingNewLocation
			? allocationTypes.map((type: AllocationSettings) => ({
					...type,
					...baseSettings,
					shiftAllocationTypeId: type.shiftAllocationTypeId,
			  }))
			: allocationTypes.map((type: AllocationSettings) => {
					const allocationSetting = locationData?.allocationSettings?.find(
						(setting: { shiftAllocationTypeId: number }) =>
							setting.shiftAllocationTypeId === type.shiftAllocationTypeId
					);

					if (!allocationSetting) {
						return {
							...type,
							...baseSettings,
							shiftAllocationTypeId: type.shiftAllocationTypeId,
						};
					}

					return {
						...mergeObjects(type, allocationSetting),
						locationId: locationData.id,
						id: allocationSetting.id,
					};
			  });

		setShiftAllocationRules(newRules);
	}, [locationData, allocationTypes, creatingNewLocation]);

	const openWorkcenterDialog = () => {
		setWorkcenterDialogOpen(true);
	};

	const closeWorkcenterDialog = () => {
		setWorkcenterDialogOpen(false);
	};

	const openSeniorityDialog = () => {
		setSeniorityDialogOpen(true);
	};

	const closeSeniorityDialog = () => {
		setSeniorityDialogOpen(false);
	};

	const openLocationDialog = () => {
		setLocationDialogOpen(true);
	};

	const closeLocationDialog = () => {
		setLocationDialogOpen(false);
	};

	const handleDefaultChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, name: string) => {
			const { value } = event.target;
			confirm({
				title: "Are you sure?",
				description: "",
				content: `Are you sure you want to set the default shift allocation rules to ${name}?`,
			})
				.then(() => {
					const newRules = shiftAllocationRules.map(
						(rule: AllocationSettings) => ({
							...rule,
							isDefault: rule.shiftAllocationTypeId === Number(value),
						})
					);
					setShiftAllocationRules(newRules);
					setLocationData((prevData: any) => ({
						...prevData,
						allocationSettings: newRules,
					}));
				})
				.catch(() => {
					/* ... */
				});
		},
		[confirm, shiftAllocationRules, setLocationData]
	);

	const handleJobRemove = useCallback(
		(shiftAllocationTypeId: number, jobId: string) => {
			const newRules = shiftAllocationRules.map((rule: AllocationSettings) =>
				rule.shiftAllocationTypeId === shiftAllocationTypeId
					? {
							...rule,
							jobIds: rule.jobIds
								.split(",")
								.filter((id) => id !== jobId)
								.join(","),
					  }
					: rule
			);
			setShiftAllocationRules(newRules);
			setLocationData((prevData: any) => ({
				...prevData,
				allocationSettings: newRules,
			}));
		},
		[shiftAllocationRules, setLocationData]
	);

	return [
		{
			allocationTypes,
			jobDialogOpen,
			seniorityDialogOpen,
			status,
			errorMessage,
			shiftAllocationRules,
			allJobsByLocation,
			allJobsByLocationObject,
			workcenterDialogOpen,
			locationDialogOpen,
		},
		{
			handleDefaultChange,
			setJobDialogOpen,
			setSeniorityDialogOpen,
			setShiftAllocationRules,
			handleJobRemove,
			openWorkcenterDialog,
			closeWorkcenterDialog,
			openSeniorityDialog,
			closeSeniorityDialog,
			openLocationDialog,
			closeLocationDialog,
		},
	];
};

export default useShiftAllocationConfigController;
