/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable simple-import-sort/imports */
import { Box, Grid } from "@mui/material";
import React, { useCallback } from "react";
import useCrossSkillingDashboardController from "../../controllers/use-cross-skilling-dashboard-controller";
import CrossSkillingDashboardDataHeader from "./cross-skilling-dashboard-header";
import SkillInsightReportTable from "../../../insight-dashboard/ui/skill-insight/skill-insight-report-table";
import WorkerInsightReportTable from "../../../insight-dashboard/ui/worker-insight/worker-insight-report-table";
import {
	Location,
	SkillInsightModel,
	WorkerInsightModel,
	columnType,
} from "../../../../components/insight-dashboard/slices/insight-report-types";

function WorkerInsightReport() {
	const [
		{
			searchText,
			allLocations,
			selectedLocations,
			allSkillLevels,
			workersReportData,
			fetchWorkerInsightReportStatus,
			overAllColumns,
			fetchSkillInsightReportStatus,
			isSkillPriorityEnabled,
			skillsWithLessThanXWorkers,
			interestedWorkers,
			workersWithLessThenXSkills,
			workersWithAllSkills,
			selectedKPI,
			kpiCount,
			overAllSkillsData,
			overAllWorkersData,
			allPriorities,
			priorities,
		},
		{
			setSearchText,
			setSelectedLocations,
			setOverAllColumns,
			dispatch,
			setKpi,
		},
	]: any = useCrossSkillingDashboardController();

	const locationColumnEnabled = overAllColumns.find(
		(item: columnType) =>
			item.field === "workerLocation" && item.checked === true
	);
	const workcenterColumnEnabled = overAllColumns.find(
		(item: columnType) => item.field === "workcenter" && item.checked === true
	);
	const primaryJobColumnEnabled = overAllColumns.find(
		(item: columnType) => item.field === "primaryJob" && item.checked === true
	);

	const islocationColumnEnabled = !!locationColumnEnabled;
	const isworkcenterColumnEnabled = !!workcenterColumnEnabled;
	const isprimaryJobColumnEnabled = !!primaryJobColumnEnabled;

	const filterWorkerData = (workersData: WorkerInsightModel[]) => {
		let filteredData = workersData;

		//Search by text
		if (searchText?.length > 0) {
			const searchTextLowerCase = searchText.toLowerCase().replace(/\s/g, "");
			filteredData = filteredData?.filter((worker) => {
				const fullName = (worker.firstName + " " + worker.lastName)
					.toLowerCase()
					.replace(/\s/g, "");
				const workecenter = isworkcenterColumnEnabled
					? worker.workcenterName.toLowerCase().replace(/\s/g, "")
					: "";
				const location = islocationColumnEnabled
					? worker.locationName.toLowerCase().replace(/\s/g, "")
					: "";
				const primaryJob =
					isprimaryJobColumnEnabled && worker.primaryJobName
						? worker.primaryJobName.toLowerCase().replace(/\s/g, "")
						: "";

				return (
					fullName.includes(searchTextLowerCase) ||
					location.includes(searchTextLowerCase) ||
					workecenter.includes(searchTextLowerCase) ||
					primaryJob.includes(searchTextLowerCase)
				);
			});
		}

		return filteredData;
	};

	const selectedLocationWithIds: Location[] = [];
	//Filter by location
	selectedLocations?.map((locationId: number) => {
		const data = allLocations.find((x: Location) => x.id === locationId);
		selectedLocationWithIds.push({
			id: data?.id,
			name: data?.name,
			isActive: data?.isActive,
		});
	});

	const filteredSkillsData = useCallback(() => {
		const skillsData =
			selectedKPI === "interestedWorkersKPI"
				? overAllSkillsData.filter(
						(skill: SkillInsightModel) => {
							const interestedWorkersCount = skill.interestedWorkersCount;
							let interestedWorkers = 0;
							interestedWorkersCount.forEach(
								(interestedWorkerCount) => {
									if (selectedLocations.length) {
										if (
											selectedLocations.includes(interestedWorkerCount.locationId)
										) {
											interestedWorkers += interestedWorkerCount.count;
										}
									} else {
										interestedWorkers += interestedWorkerCount.count;
									}
								}
							);
							return interestedWorkers > 0;
						}
				  )
				: skillsWithLessThanXWorkers;
		let filteredData: SkillInsightModel[] = skillsData;

		//Filter by search text
		if (searchText?.length > 0) {
			const searchTextLowerCase = searchText.toLowerCase().replace(/\s/g, "");

			filteredData = filteredData.filter((skill) => {
				let locationsString = "";

				skill.locationIds.map((id: any) => {
					const locationObject = allLocations.find(
						(item: Location) => item.id === id
					);
					if (locationObject) {
						locationsString += ", " + locationObject.name;
					}
				});

				const skillData: any = { ...skill };
				skillData["locations"] = locationsString;

				const skillName = skill.skillName.toLowerCase().replace(/\s/g, "");
				const locations = islocationColumnEnabled
					? skillData.locations.toLowerCase().replace(/\s/g, "")
					: "";
				return (
					skillName.includes(searchTextLowerCase) ||
					locations.includes(searchTextLowerCase)
				);
			});
		}

		return filteredData;
	}, [selectedKPI, overAllSkillsData, skillsWithLessThanXWorkers, searchText, selectedLocations, islocationColumnEnabled, allLocations]);

	return (
		<Box>
			<CrossSkillingDashboardDataHeader
				isSkillPriorityEnabled={isSkillPriorityEnabled}
				allPriorities={allPriorities}
				selectedKPI={selectedKPI}
				setKpi={setKpi}
				skillsWithLessThanXWorkers={
					selectedKPI === "skillsWithLessThenXWorkersKPI" ||
					selectedKPI === "interestedWorkersKPI"
						? filteredSkillsData()
						: skillsWithLessThanXWorkers
				}
				workersWithLessThenXSkills={
					selectedKPI === "workersWithLessThenXSkillsKPI"
						? filterWorkerData(workersWithLessThenXSkills)
						: workersWithLessThenXSkills
				}
				noOfWorkers={overAllWorkersData.length}
				noOfSkills={overAllSkillsData.length}
				kpiCount={kpiCount}
				allLocations={allLocations}
				selectedLocations={selectedLocations}
				setSelectedLocations={setSelectedLocations}
				allSkillLevels={allSkillLevels}
				setSearchText={setSearchText}
				searchText={searchText}
				overAllColumns={overAllColumns}
				setOverAllColumns={setOverAllColumns}
				fetchWorkerInsightReportStatus={fetchWorkerInsightReportStatus}
				fetchSkillInsightReportStatus={fetchSkillInsightReportStatus}
				exportData={
					selectedKPI === "workersWithLessThenXSkillsKPI"
						? filterWorkerData(workersWithLessThenXSkills)
						: selectedKPI === "workersWithAllSkillsKPI"
						? filterWorkerData(workersWithAllSkills)
						: selectedKPI === "skillsWithLessThenXWorkersKPI" ||
						  selectedKPI === "interestedWorkersKPI"
						? filteredSkillsData()
						: filterWorkerData(overAllWorkersData)
				}
			/>
			{selectedKPI !== "skillsWithLessThenXWorkersKPI" &&
			selectedKPI !== "interestedWorkersKPI" ? (
				<WorkerInsightReportTable
					allSkillLevels={allSkillLevels}
					fetchWorkerInsightReportStatus={fetchWorkerInsightReportStatus}
					workersReportdata={
						selectedKPI === "workersWithLessThenXSkillsKPI"
							? filterWorkerData(workersWithLessThenXSkills)
							: selectedKPI === "workersWithAllSkillsKPI"
							? filterWorkerData(workersWithAllSkills)
							: filterWorkerData(overAllWorkersData)
					}
					overAllColumns={overAllColumns}
					dispatch={dispatch}
					searchText={searchText}
					allLocations={selectedLocations}
					allPriorities={allPriorities}
					isSkillPriorityEnabled={isSkillPriorityEnabled}
				/>
			) : (
				<SkillInsightReportTable
					fetchSkillInsightReportStatus={fetchSkillInsightReportStatus}
					skillsReportData={filteredSkillsData()}
					overAllColumns={overAllColumns}
					searchText={searchText}
					allLocations={selectedLocationWithIds}
					dispatch={dispatch}
					priorities={priorities}
					selectedKPI={selectedKPI}
					allSkillLevels={allSkillLevels}
					selectedLocations={selectedLocations}
				/>
			)}
		</Box>
	);
}

export default WorkerInsightReport;
