import BuildIcon from "@mui/icons-material/Build";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { SkillRepresentationCell } from "../../../../../../export/cross-skilling";
import { getRepLevel, getRepType } from "../../../../../../export/helpers";
import Loading from "../../../../../../js/components/Loading";
import useInsightWorkerDetailsByLevelController
	from "../../../controllers/use-insight-worker-details-levels-controller";
import {WorkerInsightRootState} from "../../../slices/insight-report-types";

const WorkerInsightDetailsPriorityTab = () => {
	const [
		{
			searchWorkerInsightDetailsData,
		},
	] :any= useInsightWorkerDetailsByLevelController();

	const [loading, setLoading] = React.useState(true);

	const allPriorities = useSelector((state: any) => state.skills.skillPriorities);

	const allSkills = useSelector((state: any) => state.skills.masterData.skills);

	const allSkillLevels = useSelector(
		(state: WorkerInsightRootState) =>
			state.workerInsightReportData.skillsLevelLegendsData
	);

	const workerProfile = useSelector(
		(state: any) => state.manageWorkers.workerProfile.profile
	);

	const workerProfileStatus = useSelector(
		(state: any) => state.manageWorkers.workerProfile.status
	);

	const workerJobs = workerProfile?.jobs || [];
	const workerOtherSkills = workerProfile?.otherSkills || [];

	const workerSkills: any = {};
	workerJobs.forEach((job: any) => {
		job.skills.forEach((skill: any) => {
			workerSkills[skill.skillId] = skill;
		});
	});

	workerOtherSkills.forEach((skill: any) => {
		workerSkills[skill.skillId] = skill;
	});

	useEffect(() => {
		if (workerProfileStatus === "fulfilled" && allPriorities && allSkills) {
			setLoading(false);
		}
	}, [workerProfileStatus, allPriorities, allSkills]);

	const getPriorityNameAndCode = (priorityId: number) => {
		let returnValue = "";
		allPriorities.forEach((priority: any) => {
			if (priority.id === priorityId) {
				returnValue = `${priority.code} - ${priority.name}`;
			}
		});
		return returnValue;
	};

	const getSkillLevelByLevel = (level: number) => {
		let returnValue = allSkillLevels[0];
		allSkillLevels.forEach((skillLevel: any) => {
			if (skillLevel.level === level) {
				returnValue = skillLevel
			}
		});
		return returnValue
	}

	if (loading || workerProfileStatus === "pending")
		return (
			<Box>
				<Loading />
			</Box>
		);
	return (
    <Box>
      {searchWorkerInsightDetailsData?.workerSkillsByPriority?.map(
        (priority: any) => (
          <Accordion key={priority.priorityId}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {`${getPriorityNameAndCode(priority.priorityId)} (${
                priority.workerSkillsByLevel.length
              })`}
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {priority.workerSkillsByLevel.map((skill: any, index: number) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    key={`worker-skill-level-${skill.level}-${index}`}
                    m={1}
                  >
                    <SkillRepresentationCell
                      level={skill.level || 0}
                      // backgroundColor={workerSkills[skill.id].backColorCode}
                      // foregroundColor={workerSkills[skill.id].foreColorCode}
                      value={getSkillLevelByLevel(skill.level).value ?? ""}
                      representationType={getRepType(
                        getSkillLevelByLevel(skill.level)?.repCode
                      )}
                      representationLevel={getRepLevel(
                        getSkillLevelByLevel(skill.level).repCode,
                        getSkillLevelByLevel(skill.level).level
                      )}
                      border={1}
                      backgroundWidth={"35px"}
                      backgroundHeight={"35px"}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        marginLeft: "10px",
                        minWidth: "200px",
                      }}
                    >
                      {skill.skillName}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      )}
		{!loading && searchWorkerInsightDetailsData?.workerSkillsByPriority?.length === 0 && (
			<Box sx={{ alignItems: "center", display: "flex", flexDirection: "column", mt: 10 }}>
				<BuildIcon sx={{ color: "#6c6d6e", fontSize: "40px" }} />
				No skills available
			</Box>
		)}
    </Box>
  );
};

export default WorkerInsightDetailsPriorityTab;
