/* eslint-disable simple-import-sort/imports */
import SortedDatagridPro6 from "../../../../design/sorted-datagrid-pro-6";
import { Box, Tooltip } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import CrossSkillLegends from "../../../cross-skilling/ui/cross-skill-header/ui/cross-skill-ledgends";
import SkeletonLoadingOverlay from "../../../../utils/use-skeleton-loading-overlay";
import useTableHeight from "../../../../utils/use-table-height";
import Highlight from "../../../Highlight";
import { openWorkerInsightDetails } from "../../slices/worker-insight-report-slice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const WorkerInsightReportTable = ({
	workersReportdata,
	searchText,
	fetchWorkerInsightReportStatus,
	allSkillLevels,
	overAllColumns,
	dispatch,
	allLocations,
	allPriorities,
	isSkillPriorityEnabled,
}: any) => {
	const { height, boxRef } = useTableHeight();

	let rows: any = [];
	const col: any = [];

	//Used in Column selector
	const location: boolean = overAllColumns.find(
		(item: any) => item.field === "workerLocation" && item.checked === true
	);
	const workCenter: boolean = overAllColumns.find(
		(item: any) => item.field === "workcenterName" && item.checked === true
	);
	const workerName: boolean = overAllColumns.find(
		(item: any) => item.field === "workerName" && item.checked === true
	);
	const joiningDate: boolean = overAllColumns.find(
		(item: any) => item.field === "joiningDate" && item.checked === true
	);
	const interestedSkills: boolean = overAllColumns.find(
		(item: any) => item.field === "interestedSkills" && item.checked === true
	);
	const totalSkills: boolean = overAllColumns.find(
		(item: any) => item.field === "totalSkills" && item.checked === true
	);
	const workerBackup: boolean = overAllColumns.find(
		(item: any) => item.field === "workerBackupCount" && item.checked === true
	);

	const skillScore: boolean = overAllColumns.find(
		(item: any) => item.field === "skillScore" && item.checked === true
	);
	const primaryJob: boolean = overAllColumns.find(
		(item: any) => item.field === "primaryJobName" && item.checked === true
	);

	//Worker Name Column
	workerName &&
		col.push({
			field: "workerName",
			headerName: "Worker Name",
			minWidth: 130,
			flex: 1,
			renderHeader: () => (
				<Tooltip title='Worker Name'>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Worker Name"}
					</span>
				</Tooltip>
			),
			renderCell: (params: any) => {
				const fullName = `${params?.row?.workerName}`;
				return (
					<Tooltip title={fullName}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								marginLeft: "12px",
							}}
						>
							<Highlight searchText={searchText} value={fullName} />
						</span>
					</Tooltip>
				);
			},
		});

	//Location Name Column
	location &&
		col.push({
			field: "workerLocation",
			headerName: "Worker Location",
			minWidth: 138,
			flex: 1,
			renderHeader: () => (
				<Tooltip title='Worker Location'>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Worker Location"}
					</span>
				</Tooltip>
			),
			renderCell: (params: any) => (
				<Tooltip title={params.value}>
					<span
						style={{
							marginLeft: "12px",
							textOverflow: "ellipsis",
							overflow: "hidden",
						}}
					>
						<Highlight
							searchText={searchText}
							value={params?.row?.workerLocation}
						/>
					</span>
				</Tooltip>
			),
		});

	//WorkCenter Name Column
	workCenter &&
		col.push({
			field: "workcenterName",
			headerName: "Workcenter",
			minWidth: 130,
			flex: 1,
			renderHeader: () => (
				<Tooltip title='Workcenter'>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Workcenter"}
					</span>
				</Tooltip>
			),
			renderCell: (params: any) => (
				<Tooltip title={params.value}>
					<span
						style={{
							marginLeft: "12px",
							textOverflow: "ellipsis",
							overflow: "hidden",
						}}
					>
						<Highlight
							searchText={searchText}
							value={params?.row?.workcenterName ?? "-"}
						/>
					</span>
				</Tooltip>
			),
		});

	primaryJob &&
		col.push({
			field: "primaryJobName",
			headerName: "Primary Job",
			minWidth: 180,
			flex: 1,
			renderHeader: () => (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Primary Job"}
					</span>
				</Box>
			),
			renderCell: (params: any) => (
				<Tooltip title={params.value}>
					<span
						style={{
							marginLeft: "12px",
							textOverflow: "ellipsis",
							overflow: "hidden",
						}}
					>
						<Highlight
							searchText={searchText}
							value={params?.row?.primaryJobName || "-"}
						/>
					</span>
				</Tooltip>
			),
			valueGetter: (params: any) => {
				return params.row.primaryJobName;
			},
		});

	allPriorities &&
		isSkillPriorityEnabled &&
		allPriorities.length > 0 &&
		allPriorities.forEach((x: any) => {
			if (col.find((item: any) => item.field === `priority${x.id}`)) return;
			else
				col.push({
					field: `priority${x.id}`,
					align: "center",
					renderHeader: () => (
						<Tooltip title={`${x.code} (${x.name})`}>
							<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
								{`${x.code} (${x.name})`}
							</span>
						</Tooltip>
					),
					headerName: x.name,
					minWidth: 120,
					flex: 1,
					headerAlign: "center",
					renderCell: (params: any) => {
						return params.row[`priority${x.id}`]
							? params.row[`priority${x.id}`]
							: "-";
					},
				});
		});

	//Joining Date column
	joiningDate &&
		col.push({
			field: "joiningDate",
			headerName: "Joining Date",
			minWidth: 115,
			flex: 1,
			renderHeader: () => (
				<Tooltip title='Joining Date'>
					<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
						{"Joining Date"}
					</span>
				</Tooltip>
			),
			renderCell: (params: any) => {
				const joiningDate = params?.row.joiningDate.replace(/-/g, "/");
				return (
					<Tooltip title={joiningDate}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								marginLeft: "12px",
							}}
						>
							{joiningDate}
						</span>
					</Tooltip>
				);
			},
		});

	//Skill coverage column
	totalSkills &&
		col.push({
			field: "totalSkills",
			headerName: "Total Skills",
			minWidth: 148,
			flex: 1,
			align: "center",
			headerAlign: "center",
			renderHeader: () => {
				const text =
					!isSkillPriorityEnabled && allSkillLevels.length > 0
						? allSkillLevels
								.filter(
									(curr: { isQualifiedAsSkilled: boolean }) =>
										curr.isQualifiedAsSkilled
								)
								.map((curr: { name: string }) => curr.name)
								.join(", ")
						: "";

				const tooltip =
					!isSkillPriorityEnabled && allSkillLevels.length > 0
						? `Total number of skills with ${text}`
						: "Total number of skills the worker has qualified as skilled";

				return (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
							Total Skills
						</span>
						<Tooltip title={tooltip}>
							<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />
						</Tooltip>
					</Box>
				);
			},
		});

	isSkillPriorityEnabled &&
    skillScore &&
    col.push({
      field: "skillScore",
      headerName: "Score",
      minWidth: 180,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <span style={{ padding: "0px 6px", fontWeight: "bold" }}>
            {"Total Skill Score"}
          </span>
        </Box>
      ),
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <span
            style={{
              marginLeft: "12px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <Highlight
              searchText={searchText}
              value={`${params?.row?.skillScore}`}
            />
          </span>
        </Tooltip>
      ),
    });

	//Interested skills column
	interestedSkills &&
		col.push({
			field: "interestedSkills",
			headerName: "Interested Skills",
			minWidth: 185,
			flex: 1,
			align: "center",
			headerAlign: "center",
			renderHeader: () => (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
						{"Interested Skills"}
					</span>
					<Tooltip title='Count of skills worker have shown interest for'>
						<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />
					</Tooltip>
				</Box>
			),
		});

	//Worker backup column
	workerBackup &&
		col.push({
			field: "workerBackupCount",
			headerName: "Worker Backup",
			minWidth: 180,
			flex: 1,
			align: "center",
			headerAlign: "center",
			renderHeader: () => (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<span style={{ padding: "0px 6px", fontWeight: "bold" }}>
						{"Worker Backup"}
					</span>
					<Tooltip title='List of workers with same set of skills'>
						<InfoOutlinedIcon sx={{ fontSize: "18px", color: "#6c6d6e" }} />
					</Tooltip>
				</Box>
			),
		});

	//Dynamic skill levels columns
	!isSkillPriorityEnabled &&
		allSkillLevels !== undefined &&
		allSkillLevels.length > 0 &&
		allSkillLevels.map((x: any) => {
			col.push({
				field: `level${x.level}`,
				align: "center",
				renderHeader: () => (
					<Box>
						<CrossSkillLegends
							isSkillLevelShownInTableHeader={true}
							allSkillLevels={[allSkillLevels[x.level]]}
						/>
					</Box>
				),
				headerName: x.name,
				minWidth: 110,
				flex: 1,
				headerAlign: "center",
				renderCell: (params: any) => {
					return params.row[`level${x.level}`]
						? params.row[`level${x.level}`]
						: "-";
				},
			});
		});

	rows = workersReportdata
		.map((item: any) => {
			const skills: any = {};
			let skillsCoverageTotal = 0;

			item.workerSkillCountByLevel.map((skill: any) => {
				skills[`level${skill.level}`] = skill.count;
				const level = allSkillLevels.find(
					(x: { level: number }) => x.level === skill.level
				);
				if (level && level.isQualifiedAsSkilled) {
					skillsCoverageTotal += skill.count;
				}
			});

			const priority: any = {};
			item.workerSkillCountByPriority.map((w: any) => {
				priority[`priority${w.priorityId}`] = w.count;
			});

			return {
				...item,
				id: item.workerId,
				workerLocation: item.locationName,
				workCenter: !item.workcenterName ? "-" : item.workcenterName,
				workerName: (item.firstName + " " + item.lastName).trim(),
				joiningDate: format(new Date(item.joiningDate), "MM-dd-yyyy"),
				interestedSkills: item.interestedSkillsCount,
				totalSkills: skillsCoverageTotal,
				workerBackup: item.workerBackupCount,
				skillScore: item.skillScore,
				primaryJobName: item.primaryJobName,
				...priority,
				...skills,
			};
		})
		.sort((a: any, b: any) => a.workerName.localeCompare(b.workerName));

	const handleRowClick = (params: any) => {
		const workerId = params.row.id;
		dispatch(
			openWorkerInsightDetails({ workerId: workerId, locations: allLocations })
		);
	};

	return (
		<Box
			ref={boxRef}
			style={{
				overflow: "hidden",
				width: "100%",
				height: "calc(100vh - 320px)",
				minHeight: "200px",
			}}
		>
			<SortedDatagridPro6
				rows={rows}
				columns={col}
				onRowClick={handleRowClick}
				experimentalFeatures={{ columnGrouping: true }}
				loading={fetchWorkerInsightReportStatus === "pending"}
				defaultSortField='workerName'
				slots={{
					loadingOverlay: () => (
						<SkeletonLoadingOverlay
							columnData={{
								columnName: "workerName",
								columnStyle: { marginLeft: "12px" },
							}}
						/>
					),
				}}
				disableColumnFilter
				disableColumnMenu
				pagination
				disableColumnSelector
				disableDensitySelector
				disableColumnReorder
				sx={{
					overflow: "hidden",
					border: "none !important",
					"& .MuiDataGrid-row": {
						cursor: "pointer",
					},
					"& .MuiDataGrid-cell:focus": {
						outline: "none",
					},
				}}
			/>
		</Box>
	);
};
export default WorkerInsightReportTable;
