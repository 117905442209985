import React from "react";
import { useSelector } from "react-redux";

import { SkillInsightRootState } from "../slices/insight-report-types";
const useInsightSkillDetailsByLevelController = () => {

    const [searchText, setSearchText] = React.useState("")
    const [expanded, setExpanded] = React.useState<string[]>([])

	const skillInsight = useSelector(
		(state: SkillInsightRootState) => state.skillInsightReportData.skillInsightDetailsData
	)
    let searchSkillInsightDetailsData = skillInsight;
    if (searchText?.length > 0) {
        
        let workersList:any = [];
        let interestedWorkersList :any= []
        const searchTextLowerCase = searchText.toLowerCase().replace(/\s/g, "");
        workersList = skillInsight.workersList.filter((worker) => {
            return ((worker.workerName).toLowerCase().replace(/\s/g, "").includes(searchTextLowerCase))
        })
        interestedWorkersList = skillInsight?.interestedWorkersList?.filter((interestedWorker) => {
            return ((interestedWorker.workerName).toLowerCase().replace(/\s/g, "").includes(searchTextLowerCase))
        })
        searchSkillInsightDetailsData = { workersList: workersList, interestedWorkersList: interestedWorkersList }
    }
    return [
        {
            searchText,
            searchSkillInsightDetailsData,
            expanded
        },
        {
            setSearchText,
            setExpanded
        }
    ]
}
export default useInsightSkillDetailsByLevelController;